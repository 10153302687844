import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-adblock-close-confirm-modal',
  templateUrl: './adblock-close-confirm-modal.component.html',
  styleUrls: ['./adblock-close-confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, MtSvgComponent, DefaultBoxComponent, ButtonComponent]
})
export class AdblockCloseConfirmModalComponent {
  constructor(public modal: NgbActiveModal) {}
}
