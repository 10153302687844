<div modal-body class="gated-content-info" (click)="$event.stopPropagation()">
  <ng-container modal-header>
    <div class="cursor-pointer closebutton" (click)="modal.close()">
      <mt-svg
        class="x-button"
        src="assets/images/modals/close_other_svg.svg"
      ></mt-svg>
    </div>
  </ng-container>

  <!-- icon & title -->
  <div class="flex-center-left">
    <img
      src="assets/images/common/gatedContentModalTitleIcon.png"
      class="icon-image"
    />
    <p class="title" transloco="adblocker_headline"></p>
  </div>

  <!-- text -->
  <div class="martop30">
    <p transloco="adblocker_intro"></p>
  </div>

  <!-- video placeholder -->
  <div class="martop30">
    <img
      [src]="
        translate.getActiveLang() === 'de'
          ? 'assets/images/adblock/adblocker_de.png'
          : 'assets/images/adblock/adblocker_en.png'
      "
      class="full-width"
    />
  </div>

  <!-- text -->
  <div class="martop30">
    <p transloco="adblocker_copy"></p>
  </div>

  <!-- text -->
  <div class="martop30 has-error" *ngIf="(showOopsMsg$ | async) === true">
    <p class="adblock-error-msg" transloco="adblocker_notification1"></p>
  </div>

  <!-- buttons -->
  <div class="flex-center-left martop20">
    <mt-button
      text="adblocker_btn1"
      color="primary"
      (click)="setShowOopsMsg(true)"
    ></mt-button>

    <button
      type="button"
      class="btn btnhellgrauflat marleft20 width20percent"
      transloco="adblocker_btn2"
      (click)="modal.close(false)"
    ></button>
  </div>
</div>
