import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { NgIf, AsyncPipe } from '@angular/common';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-adblock-content-modal',
  templateUrl: './adblock-content-modal.component.html',
  styleUrls: ['./adblock-content-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MtSvgComponent, TranslocoModule, NgIf, AsyncPipe, ButtonComponent]
})
export class AdblockContentModalComponent {
  /**
   * While pressed `deactivated my ad blocker` then show message
   */
  private _showOopsMsg$ = new BehaviorSubject<boolean>(false);
  showOopsMsg$ = this._showOopsMsg$.asObservable();

  constructor(public modal: NgbActiveModal, public translate: TranslocoService) {}

  /**
   * Set show message value
   * @param val - Boolean value
   */
  setShowOopsMsg(val: boolean): void {
    this._showOopsMsg$.next(val);
  }
}
