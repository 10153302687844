import { Injectable } from '@angular/core';
import { switchMap, filter, take, BehaviorSubject, tap } from 'rxjs';

// Services
import { AdblockModalService } from './adblock.modal.service';
import { IntercomService } from '@services/intercom/intercom.service';

@Injectable({
  providedIn: 'root'
})
export class AdblockService {
  /**
   * Current status of modal is opened or not
   */
  private _adblockContentModalOpened$ = new BehaviorSubject<boolean>(false);
  adblockContentModalOpened$ = this._adblockContentModalOpened$.asObservable();

  constructor(private adblockModalService: AdblockModalService, private intercomService: IntercomService) {}

  /**
   * Update value to know adblock content modal is opened or not
   * @param value - Boolean value
   */
  private changeValueOfAdblockContentModalOpened(value: boolean): void {
    this._adblockContentModalOpened$.next(value);
  }

  /**
   * Open adblock content modal
   */
  openAdblockContentModal(): void {
    this.adblockContentModalOpened$
      .pipe(
        take(1),
        filter(isOpened => !isOpened), // Check already adblock content modal opened
        tap(() => this.changeValueOfAdblockContentModalOpened(true)),
        switchMap(() => this.adblockModalService.openAdblockContentModal()),
        tap(() => this.changeValueOfAdblockContentModalOpened(false)),
        filter(res => !res), // Press `continue with ad blocker` then open confirmation modal
        switchMap(() => this.adblockModalService.openAdblockCloseConfirmModal()),
        filter(res => !res) // Press `Cancel` then only reopen adblock content modal
      )
      .subscribe(() => {
        // Reopen adblock content modal
        this.openAdblockContentModal();
      });
  }

  /**
   * Check and open adblock modal if adblock extension enabled
   */
  openAdblockContentModalIfExist(): void {
    setTimeout(() => {
      const exist = this.intercomService.isIntercomIconAvailable();
      if (!exist) {
        this.openAdblockContentModal();
      }
    }, 1000);
  }
}
