<div class="modal-header">
  <div class="title" transloco="attention_headline"></div>
  <div class="cursor-pointer close" (click)="modal.close(false)">
    <mt-svg
      class="x-button"
      src="assets/images/modals/close_other_svg.svg"
    ></mt-svg>
  </div>
</div>
<div class="modal-body">
  <mt-box type="info" translateTag="adblocker_notification2"></mt-box>
</div>
<div class="modal-footer">
  <div class="flex-center-space-between">
    <div class="flex">
      <mt-button
        text="proceeding"
        class="mt_left"
        color="default"
        (click)="modal.close(true)"
      >
      </mt-button>
      <mt-button
        text="abort"
        color="primary"
        (click)="modal.close(false)"
      ></mt-button>
    </div>
  </div>
</div>
