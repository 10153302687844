import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, take } from 'rxjs';

// Components
import { AdblockCloseConfirmModalComponent } from '@shared/components/organisms/modals/adblock-close-confirm-modal/adblock-close-confirm-modal.component';
import { AdblockContentModalComponent } from '@shared/components/organisms/modals/adblock-content-modal/adblock-content-modal.component';

@Injectable({
  providedIn: 'root'
})
export class AdblockModalService {
  /**
   * Modal options
   */
  modalOptions: NgbModalOptions = {
    animation: true,
    backdrop: 'static',
    backdropClass: 'mt_modal_default_backdrop'
  };

  constructor(private modal: NgbModal) {}

  /**
   * Open adblock content modal
   * @returns Observable of boolean
   */
  openAdblockContentModal(): Observable<boolean> {
    const modalInstance = this.modal.open(AdblockContentModalComponent, {
      ...this.modalOptions,
      backdropClass: 'light-blue-backdrop',
      keyboard: false,
      size: 'md',
      windowClass: 'mt_modal'
    });

    return from(modalInstance.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * Open adblock content close confirmation modal
   * @returns Observable of boolean
   */
  openAdblockCloseConfirmModal(): Observable<boolean> {
    const modalInstance = this.modal.open(AdblockCloseConfirmModalComponent, {
      ...this.modalOptions,
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal'
    });

    return from(modalInstance.result as Promise<boolean>).pipe(take(1));
  }
}
